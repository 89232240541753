import React, { Fragment } from "react"
import PropTypes from "prop-types"
import Box from "common/components/Box"
import Text from "common/components/Text"
import Heading from "common/components/Heading"
import Button from "common/components/Button"
import FeatureBlock from "common/components/FeatureBlock"
import Container from "common/components/UI/Container"
import Particles from "../../Agency/Particle"
import BannerWrapper, { DiscountLabel } from "./bannerSection.style"

const BannerSection = ({
  row,
  col,
  title,
  btnStyle,
  description,
  discountText,
  discountAmount,
  outlineBtnStyle,
}) => {
  const ButtonGroup = () => (
    <Fragment>
      <Button title="LEARN MORE" {...btnStyle} />
      <Button
        title="WATCH WORKS"
        variant="textButton"
        icon={<i className="flaticon-next" />}
        {...outlineBtnStyle}
      />
    </Fragment>
  )
  return (
    <BannerWrapper>
      <Particles />
      <Container>
        <Box
          className="row"
          {...row}
          style={{ paddingTop: "100px" }}
          dir="rtl"
          lang="ar"
        >
          <Box className="col-12" dir="rtl" lang="ar">
            {/* <DiscountLabel>
              <Text content="25% Discount" {...discountAmount} />
              <Text content="on every first project budget" {...discountText} />
            </DiscountLabel> */}
            <FeatureBlock
              dir="rtl"
              lang="ar"
              title={
                <Heading
                  style={{ color: "#f6c04d" }}
                  content="الشروط والأحكام"
                  {...title}
                />
              }
              description={
                <>
                  <Text
                    style={{ color: "#fff", opacity: "0.8" }}
                    content="نحن شركة نيون للدعاية والإعلان والخدمات التكنولوجية، ونقوم بأعمال تجارية باسم نيون "
                    {...description}
                  />
                  <Text
                    style={{ color: "#fff", opacity: "0.8" }}
                    content={
                      <>
                        (شركة" أو "نحن" أو "لنا" أو "خاصتنا")، وهي شركة مسجلة{" "}
                      </>
                    }
                    {...description}
                  />
                  <Text
                    style={{ color: "#fff", opacity: "0.8" }}
                    content={
                      <>
                        في مصر في 13 شارع أحمد فؤاد، النزهة، مصر الجديدة،
                        القاهرة، مصر.
                      </>
                    }
                    {...description}
                  />
                </>
              }
              // button={<ButtonGroup />}
            />
          </Box>
        </Box>
      </Container>
    </BannerWrapper>
  )
}

BannerSection.propTypes = {
  title: PropTypes.object,
  btnStyle: PropTypes.object,
  description: PropTypes.object,
  contentStyle: PropTypes.object,
  discountText: PropTypes.object,
  discountAmount: PropTypes.object,
  outlineBtnStyle: PropTypes.object,
}

BannerSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: "wrap",
    ml: "15px",
    mr: "-15px",
    alignItems: "center",
  },
  col: {
    pr: "15px",
    pl: "15px",
    width: ["100%", "80%", "70%", "50%"],
  },
  title: {
    fontSize: ["26px", "34px", "42px", "55px"],
    fontWeight: "300",
    color: "#0f2137",
    letterSpacing: "-0.025em",
    mb: ["20px", "25px"],
    lineHeight: "1.31",
  },
  description: {
    fontSize: "16px",
    color: "#343d48cc",
    lineHeight: "2.1",
    mb: "0",
  },
  btnStyle: {
    minWidth: ["120px", "156px"],
    fontSize: "14px",
    fontWeight: "500",
  },
  outlineBtnStyle: {
    minWidth: ["130px", "156px"],
    fontSize: "14px",
    fontWeight: "500",
    color: "#0f2137",
    p: "5px 10px",
  },
  discountAmount: {
    fontSize: "14px",
    color: "#f6c04d",
    mb: 0,
    as: "span",
    mr: "0.4em",
  },
  discountText: {
    fontSize: "14px",
    color: "#0f2137",
    mb: 0,
    as: "span",
  },
}

export default BannerSection
